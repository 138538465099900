import React from "react";

const Footer = () => {
  return (
    <footer
      className=" text-gray-400 flex"
      id="contact-us"
      style={{ backgroundColor: "#1D314B" }}
    >
      <div className="w-full">
        <div className="p-2 md:p-32 pb-0">
          <h1
            className="
              text-5xl
              md:text-4xl
              w-full
              leading-normal
              font-normal
              mb-10
              text-white
            "
          >
            Want to get in touch?
          </h1>
          <ul className="space-y-3">
            <li>220 Duncan Lane, Milton Ontario</li>
            <li>(+437) 9882073</li>
            <li>s.effiong@ttcorporation.org</li>
          </ul>
        </div>

        <div className="border-t-2 px-2 md:px-32 py-5">
          <p className="text-xs">
            &copy; 2021 ttcorporation. All rights reserved.
          </p>
        </div>
      </div>
      <div></div>
    </footer>
  );
};

export default Footer;
