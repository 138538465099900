import React, { FC } from "react";
import { useState } from "react";
import { BiMenuAltRight } from "react-icons/bi";
import { logo } from "../../Images";
import { motion, AnimatePresence } from "framer-motion";
const Navbar: FC = () => {
  const [navState, setnavState] = useState(false);

  return (
    <nav
      className="
        flex
        justify-between
        fixed
        w-full
        items-center
        top-0
        left-0
        z-40
        bg-white
      "
    >
      <div
        className="
          logo
          text-white
        "
      >
        <img src={logo} alt="logo-display" className="w-32 h-20" />
      </div>

      <ul className="space-x-5 pr-5 md:flex justify-center hidden items-space-between">
        <li className="my-5 md:my-0">
          <a
            href="/"
            className="text-red-500 flex justify-center items-center flex-col"
          >
            Home
            <span className="bg-red-500 h-2 w-2 rounded-full "></span>
          </a>
        </li>
        <li className="mb-5 md:mb-0">
          <a href="#about">About Us</a>
        </li>
        <li className="mb-5 md:mb-0">
          <a href="#features">Product</a>
        </li>
        <li className="mb-5 md:mb-0">
          <a href="#mission">Mission</a>
        </li>
        <li className="mb-5 md:mb-0">
          <a href="#team">Team</a>
        </li>
        <li className="mb-5 md:mb-0">
          <a href="#contact-us">Contact Us</a>
        </li>
      </ul>

      {/* menu button */}
      <span
        className="absolute top-0 right-0 p-5 block md:hidden"
        onClick={() => setnavState(!navState)}
      >
        <BiMenuAltRight size="32" />
      </span>

      <AnimatePresence>
        {navState && (
          <motion.ul
            className="absolute space-x-5 pr-5 md:relative flex md:hidden right-0 top-0 bg-gray-50 md:bg-transparent flex-col md:flex-row w-full md:w-max md:mt-0 mt-20 justify-center items-center z-10"
            initial={{ opacity: 0, y: -20, zIndex: 10 }}
            animate={{ opacity: 1, y: "0" }}
            transition={{ type: "tween", delay: 0, duration: 0.5 }}
            exit={{ opacity: 0, y: -20, zIndex: 10 }}
          >
            <li className="my-5 md:my-0">
              <a href="#hero" className="text-red-500">
                Home
              </a>
            </li>
            <li className="mb-5 md:mb-0">
              <a href="#about">About Us</a>
            </li>
            <li className="mb-5 md:mb-0">
              <a href="#features">Product</a>
            </li>
            <li className="mb-5 md:mb-0">
              <a href="#mission">Mission</a>
            </li>
            <li className="mb-5 md:mb-0">
              <a href="#team">Team</a>
            </li>
            <li className="mb-5 md:mb-0">
              <a href="#footer">Contact Us</a>
            </li>
          </motion.ul>
        )}
      </AnimatePresence>
    </nav>
  );
};

export default Navbar;
