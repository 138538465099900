import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import { owner } from "../../Images";

const Team = () => {
  return (
    <section
      className="
        p-3
        md:p-32
        flex flex-wrap
        md:flex-nowrap
        justify-between
        md:space-x-7
        bg-gray-100
        mt-5
      "
      id="team"
    >
      <div className="relative w-full md:w-1/2 h-96">
        <img
          src={`${owner}`}
          alt=""
          className="object-cover object-left-top h-full z-10 w-full"
        />
        <div className="bg-white absolute bottom-0 right-0 h-20 w-20 flex justify-center items-center">
          <span
            className="
              text-red-600
              font-normal
              px-5
              flex
              justify-between
              items-center
            "
          >
            <FiArrowLeft size="22" color="#1D314B" />
            <FiArrowRight size="22" color="#1D314B" />
          </span>
        </div>
      </div>
      <div className="w-full md:w-1/2">
        <h6
          className="mb-5 text-lg text-gray-600 sm:mt-12"
          style={{ color: "#1D314B", fontSize: "23px" }}
        >
          Meet our team
        </h6>

        <h1
          className="
            text-2xl
            md:text-5xl
            text-gray-600
            w-full
            leading-normal
            font-light
            mb-5
          "
          style={{ color: "#1D314B" }}
        >
          Nothing but the best.
        </h1>

        <p
          className="leading-9 text-sm"
          style={{ color: "#1D314B", fontSize: "18px" }}
        >
          Sokomba Effiong is a seasoned Administrative and Human Resources
          Professional. She holds a B.sc in Economics and a Masters in Business
          Administration. Prior to being a Mumpreneur, she worked for a couple
          of Non-Governmental organisations on several US Government funded
          projects in Nigeria from project start up to completion providing
          Human Resources and Administrative leadership. She is a restaurateur
          with presence in two West African countries and still counting.
          Sokomba moved to Canada in March 2020 with an ambition to continue her
          entrepreneurial pursuits. In June 2021 she registered Tetelestai
          Trading Corporation to connect West African producers and exporters
          with the importers and exporters in the Canadian Market and vice versa
        </p>
      </div>
    </section>
  );
};

export default Team;
