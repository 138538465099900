import React from "react";

const Values = () => {
  return (
    <section className="p-3 md:p-32">
      <h6
        className="text-lg text-gray-600 mb-5"
        style={{ color: "#1D314B", fontSize: "23px" }}
      >
        What are our values?
      </h6>
      <h1
        className="
          text-2xl
          md:text-5xl
          text-gray-600
          w-full
          leading-normal
          font-light
          mb-5
        "
        style={{ color: "#1D314B" }}
      >
        We believe in...
      </h1>

      <div
        className="
          flex flex-wrap
          md:flex-nowrap
          justify-between
          md:space-x-5
          space-y-5
        "
      >
        <div className="w-full md:w-1/3">
          <p
            className="test-base"
            style={{ color: "#1D314B", fontSize: "20px" }}
          >
            Integrity
          </p>
          <p
            className="text-sm leading-9"
            style={{ color: "#1D314B", fontSize: "18px" }}
          >
            We’re honest, transparent and committed to doing what’s best for our
            customers and our company. We openly collaborate in pursuit of the
            truth.
          </p>
        </div>
        <div className="w-full md:w-1/3">
          <p
            className="test-base"
            style={{ color: "#1D314B", fontSize: "20px" }}
          >
            Honesty
          </p>
          <p
            className="text-sm leading-9"
            style={{ color: "#1D314B", fontSize: "18px" }}
          >
            We build trust through constructive, candid communication that
            serves the common good.
          </p>
        </div>
        <div className="w-full md:w-1/3">
          <p
            className="test-base"
            style={{ color: "#1D314B", fontSize: "20px" }}
          >
            Fair play
          </p>
          <p
            className="text-sm leading-9"
            style={{ color: "#1D314B", fontSize: "18px" }}
          >
            We have a lot to learn and trust to earn
          </p>
        </div>
      </div>
    </section>
  );
};

export default Values;
