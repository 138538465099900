import {
  HeroImage,
  spice,
  ginger,
  spice2,
  cocoa,
  spoons,
} from "../../Images/index";

const images = [HeroImage, spice, ginger, spice2, cocoa, spoons];

let image;
let counter = 0;

const changeImg = () => {
  if (counter < images.length - 1) {
    counter++;
  } else if (counter >= images.length - 1) {
    counter = 0;
  }
  image = images[counter];
  return image;
};

export { changeImg };
