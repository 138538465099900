import { spices, mixedSpices, seedJar, coffeebeans } from "../../Images";

const Features = () => {
  return (
    <section
      className="flex p-3 md:p-32 justify-between flex-wrap"
      id="features"
    >
      <div>
        <h6
          className="text-lg text-gray-600 mb-5"
          style={{ fontSize: "1.4rem", color: "#1D314B" }}
        >
          What do we sell?
        </h6>

        <div className="section-head">
          <h1
            className="
              text-2xl
              md:text-4xl
              text-gray-600
               leading-9
              md:leading-loose
              font-light
              mb-5
              w-4/5
            "
            style={{ color: "#1D314B" }}
          >
            We have what you're looking for.
          </h1>
        </div>

        <ul
          className="space-y-4 mb-4"
          style={{ fontSize: "18px", color: "#1D314B" }}
        >
          <li className="flex items-center">
            <span className="bg-red-500 h-2 w-2 rounded-full mr-2"></span>Palm
            oil
          </li>
          <li className="flex items-center">
            <span className="bg-red-500 h-2 w-2 rounded-full mr-2"></span>Ginger
          </li>
          <li className="flex items-center">
            <span className="bg-red-500 h-2 w-2 rounded-full mr-2"></span>Cocoa
            beans
          </li>
          <li className="flex items-center">
            <span className="bg-red-500 h-2 w-2 rounded-full mr-2"></span>
            Tumeric
          </li>
          <li className="flex items-center">
            <span className="bg-red-500 h-2 w-2 rounded-full mr-2"></span>Sesame
            seeds
          </li>
          <li className="flex items-center">
            <span className="bg-red-500 h-2 w-2 rounded-full mr-2"></span>Shea
            butter
          </li>
          <li className="flex items-center">
            <span className="bg-red-500 h-2 w-2 rounded-full mr-2"></span>Spices
          </li>
        </ul>
      </div>
      <div
        className="images md:space-x-2 "
        style={{
          display: "flex",
          alignItems: "center",
          flex: 1,
        }}
      >
        <div className="space-y-2 md:mb-0 ">
          <img
            src={`${coffeebeans}`}
            className="h-52 w-full md:w-52 object-cover"
            alt=""
            style={{ height: 300, width: 300 }}
          />
          <img
            src={`${mixedSpices}`}
            className="h-52 w-full md:w-52 object-cover"
            alt=""
            style={{ height: 300, width: 300 }}
          />
        </div>
        <div className="md:-mt-40 space-y-2">
          <img
            src={`${spices}`}
            className="h-52 w-full md:w-52 object-cover"
            alt=""
            style={{ height: 300, width: 300 }}
          />
          <img
            src={`${seedJar}`}
            className="h-52 w-full md:w-52 object-cover"
            alt=""
            style={{ height: 300, width: 300 }}
          />
        </div>
      </div>
    </section>
  );
};

export default Features;
