import React, { FC } from "react";

const AboutUs: FC = () => {
  return (
    <div id="about">
      <section className="bg-gray-100 md:p-32 p-3">
        <h6
          className="text-lg text-gray-600 mb-4"
          style={{ fontSize: "1.4rem", color: "#1D314B" }}
        >
          Who are we?
        </h6>

        <div className="section-head">
          <h1
            className="
            text-2xl
            md:text-5xl
            text-gray-600
            w-full
            md:w-2/3 md:pr-24
            leading-9
            font-light
            mb-5
          "
            style={{ color: "#1D314B" }}
          >
            We don't break barriers, we shatter them.
          </h1>
        </div>
        <div
          className="
          section-content
          flex
          md:flex-nowrap
          flex-wrap
          md:space-x-7 md:w-9/12
        "
          style={{ color: "#1D314B" }}
        >
          <p className="leading-9 mb-5 md:mb-0" style={{ fontSize: "18px" }}>
            Tetelestai Trading Corporation is a Canadian Sourcing agency within
            the food and beverage industry that links Suppliers to buyers. As
            food enthusiasts with strong passion for natural foods, we derive
            immense pleasure connecting the best quality farm producers
            (Aggregators) to merchants or industries seeking raw materials for
            onward production or distribution.
          </p>
          <p className="leading-9" style={{ fontSize: "18px" }}>
            We do not compromise on quality and assure our clients of the
            world’s best standards. We would like to be part of consumer stories
            when they walk down the grocery lines and purchase food from other
            countries. We will also ensure you never run out of your favorite
            food in or out of season because out of season may be in season from
            one of our sources.
          </p>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
