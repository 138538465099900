import React from "react";
import { freshGinger, sponSpices, spices } from "../../Images";

const MissionAndVision = () => {
  return (
    <section
      className="space-y-3 flex flex-col p-3 md:p-32 justify-center items-center"
      id="mission"
    >
      <div className="flex justify-between md:flex-nowrap flex-wrap mb-5">
        <div className="md:pr-20 w-full md:w-1/2">
          <h1
            className="
              text-2xl
              md:text-4xl
              text-gray-900
              w-full
              leading-normal
              font-light
              mb-5
            "
            style={{ color: "#1D314B" }}
          >
            Our mission
          </h1>
          <p
            className="leading-10"
            style={{ color: "#1D314B", fontSize: "18px" }}
          >
            To redirect valuable time companies spend on sourcing products to
            other profitable areas while we undertake your sourcing needs within
            your budgets
          </p>
        </div>

        <div className="md:pr-20">
          <h1
            className="
              text-2xl
              md:text-4xl
              text-gray-900
              w-full
              leading-normal
              font-light
              mb-5
            "
            style={{ color: "#1D314B" }}
          >
            Our vision
          </h1>
          <p
            className="leading-10"
            style={{ color: "#1D314B", fontSize: "18px" }}
          >
            To be the best broker of international trade between West Africa and
            the world.
          </p>
        </div>
      </div>

      <div
        className="
          images
          flex
          w-full
          md:flex-nowrap
          flex-wrap
          space-x-0
          md:space-x-3
          space-y-3
          md:space-y-0
        "
      >
        <img
          src={`${spices}`}
          alt=""
          className="w-full md:w-1/3 h-72 object-cover"
        />
        <img
          src={`${sponSpices}`}
          alt=""
          className="md:transform md:translate-y-20 w-full md:w-1/3 h-72 object-cover"
        />
        <img
          src={`${freshGinger}`}
          alt=""
          className="w-full md:w-1/3 h-72 object-cover"
        />
      </div>
    </section>
  );
};

export default MissionAndVision;
