import React, { FC } from "react";
import AboutUs from "./components/AboutUs/AboutUs";
import Features from "./components/Features/Features";
// import Footer from "./components/Footer/Footer";
import Hero from "./components/Hero/Hero";
import Layout from "./components/Layout/Layout";
import MissionAndVision from "./components/MissionAndVision/MissionAndVision";
// import Navbar from "./components/Navbar/Navbar";
import Team from "./components/Team/Team";
import Values from "./components/Values/Values";

const App: FC = () => {
  return (
    <div className="App font-sofiapro">
      <Layout>
        <Hero />
        <AboutUs />
        <Features />
        <MissionAndVision />
        <Values />
        <Team />
      </Layout>
    </div>
  );
};

export default App;
