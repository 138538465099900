import React, { useState } from "react";
import { FiArrowDown, FiArrowRight } from "react-icons/fi";
import { HeroImage } from "../../Images/index";
import { changeImg } from "./HeroCarousel";
import { motion } from "framer-motion";

const Hero = () => {
  let source: any;
  const [imgSrc, setimgSrc] = useState(HeroImage);
  const [mutateState, setMutate] = useState(true);
  const handleCarousel = () => {
    source = changeImg();
    setimgSrc(source);
    setMutate(!mutateState);
  };

  return (
    <header className="mt-32 md:mt-20">
      <div className="header-text flex justify-center">
        <h1
          className="
            text-3xl
            md:text-5xl
            text-gray-600
            md:p-32
            p-3
            mr-0
            md:mr-60
            leading-10
            md:leading-loose
            font-light
            font-sans
            mb-5 md:mb-0
          "
          style={{ color: "#1D314B" }}
        >
          We facilitate the most competitive food and beverage sales between
          Nigeria & West Africa.
        </h1>
      </div>

      <div className="header-carousel flex w-full pl-3 justify-between bg-gray-100">
        <div className="relative w-full z-0 h-full">
          {mutateState && (
            <motion.img
              src={`${imgSrc}`}
              alt=""
              className="object-cover h-full w-full z-10"
              initial={{ opacity: 0, x: "-100vw" }}
              animate={{ opacity: 1, x: "0" }}
              transition={{ type: "tween", delay: 0, duration: 0.1 }}
            />
          )}
          {!mutateState && (
            <motion.img
              src={`${imgSrc}`}
              alt=""
              className="object-cover h-full w-full z-10"
              initial={{ opacity: 0, x: "-100vw" }}
              animate={{ opacity: 1, x: "0" }}
              transition={{ type: "tween", delay: 0, duration: 0.1 }}
            />
          )}
          <div className="bg-white absolute bottom-0 right-0 z-0">
            <span
              className="
                text-red-600 text-7xl
                font-normal
                flex
                justify-center
                items-center
                text-center
                h-20 w-20
                cursor-pointer
              "
              onClick={handleCarousel}
            >
              <FiArrowRight size="32" color="#1D314B" />
            </span>
          </div>
        </div>
        <div>
          <a
            href="#footer"
            className="bg-white h-1/2 text-red-600 font-normal w-20 text-center flex
                justify-center
                items-center"
          >
            <FiArrowDown size="32" color="#1D314B" />
          </a>
        </div>
      </div>
    </header>
  );
};

export default Hero;
