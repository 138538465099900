import React from "react";
import { FC } from "react";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";

const Layout: FC<{ children: any }> = ({ children }) => {
  return (
    <>
      <Navbar />
      <main className="space-y-6 md:space-y-0">{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
